<template>
  <b-modal
    :active.sync="dialog"
    scroll="keep"
    :can-cancel="false"
    class="document-dialog"
    :style="{ width: $responsive.width + 'px' }"
  >
    <div
      class=" dialog modal-card"
      style="width: auto"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          Saving...
        </p>
      </header>
      <section class="modal-card-body">
        <div
          v-if="isSavingProgress"
          class="save-progress-bar"
        >
          <b-progress type="is-success" />
        </div>
        <div v-if="isSavingFailed">
          <p> Cannot save the document. Please try again later. </p>
        </div>
        <div v-if="isSavingComplete">
          <p> Saving is complete </p>
        </div>
      </section>
    </div>
  </b-modal>
</template>
<script>
import axios from 'axios';
import { eventHub } from "@/commons/event-bus";

export default {
  props: {
    visibility: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      isSavingComplete: false,
      isSavingFailed : false,
      isSavingProgress: true,
      selectedModuleIdToMove: -1,
      emails: [],
    };
  },
  watch: {
    visibility(value) {
      this.dialog = value;
    },
  },
  mounted() {
    eventHub.$on("document.submitted", this.submitComplete);
  },
  destroyed() {
    eventHub.$off("document.submitted", this.submitComplete);
  },
  methods: {
    handleClose() {
      this.dialog = false;
      this.isSavingComplete = false;
      this.isSavingFailed = false;
      this.isSavingProgress = true;
      this.$emit('handleProgressSaveCloseDialog', this.dialog, null);
    },
    submitComplete(isSuccess) {
      this.isSavingProgress = false;
      if(isSuccess){
        this.isSavingComplete = true;
      }else{
        this.isSavingFailed = true
      }
       
    }
  },
};
</script>

<style>
.document-dialog {
  z-index: 1;
}

.modal {
  z-index: 100;
}
.document-dialog > .modal-background {
  background-color: transparent;
}
.modal-content {
  box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -moz-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
  -webkit-box-shadow: 0px 0px 16px 12px rgba(119, 119, 119, 0.3);
}

.modal-card-head,
.modal-card-foot {
  background-color: #ffffff !important;
}
.modal-card-foot {
  padding: 10px;
}

.modal-card-foot {
  border-top: none;
}

.action-buttons {
  width: 100%;
}
.signature-pad {
  border: 1px solid #cccccc;
}
/* .modal-background {
  margin: 0;
  height: 100%;
  overflow: hidden;
  height: 100%;
  overflow-y: scroll;
} */
.save-progress-bar {
  min-height: 100px;
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
}
</style>
