var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isPdfLoadingComplete
        ? _c(
            "nav",
            { staticClass: "navbar has-shadow is-fixed-bottom level" },
            [
              _c("div", { staticClass: "level-left" }, [
                _c(
                  "div",
                  { staticClass: "level-item" },
                  [
                    _vm.signatureList.length == 0
                      ? _c(
                          "b-button",
                          {
                            attrs: {
                              size: "is-medium",
                              type: "is-text action-button",
                              "icon-left": "signature-freehand"
                            },
                            on: {
                              click: function($event) {
                                _vm.isSignaturePadShown = true
                              }
                            }
                          },
                          [_vm._v(" Signature ")]
                        )
                      : _c(
                          "b-dropdown",
                          {
                            attrs: {
                              "aria-role": "list",
                              position: "is-top-right",
                              "mobile-modal": true
                            }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "button is-text action-button is-medium",
                                attrs: { slot: "trigger" },
                                slot: "trigger"
                              },
                              [
                                _c("span", { staticClass: "icon" }, [
                                  _c("i", {
                                    staticClass: "mdi mdi-signature-freehand"
                                  })
                                ]),
                                _c("span", [_vm._v("Signature")])
                              ]
                            ),
                            _vm._l(_vm.signatureList, function(sign) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: sign.id,
                                  attrs: { "aria-role": "listitem" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addSelectedSignature(sign)
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "preview-signature-menu" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          width: "100",
                                          height: "80",
                                          src: sign.image.src
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            }),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: { "aria-role": "listitem" },
                                on: {
                                  click: function($event) {
                                    _vm.isSignaturePadShown = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "button is-text action-button is-medium"
                                  },
                                  [_vm._v(" Add Signature ")]
                                )
                              ]
                            )
                          ],
                          2
                        )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "div",
                  { staticClass: "level-item has-text-centered" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "button is-primary",
                        attrs: { "icon-left": "content-save-all-outline" },
                        on: { click: _vm.onFinish }
                      },
                      [_vm._v(" Submit ")]
                    )
                  ],
                  1
                )
              ])
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "columns is-centered" }, [
          _c("div", { staticClass: "column is-half is-four-fifths" }, [
            _vm.document
              ? _c("div", { staticClass: "level " }, [
                  _c("div", { staticClass: "level-left" }, [
                    _c("div", { staticClass: "level-item" }, [
                      _c(
                        "nav",
                        {
                          staticClass: "breadcrumb",
                          attrs: { "aria-label": "breadcrumbs" }
                        },
                        [
                          _c("ul", [
                            _vm._m(0),
                            _c("li", { staticClass: "is-active" }, [
                              _c("a", { attrs: { "aria-current": "page" } }, [
                                _vm._v(_vm._s(_vm.document.name))
                              ])
                            ])
                          ])
                        ]
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "level-right" }, [
                    _c(
                      "div",
                      { staticClass: "level-item" },
                      [
                        _c("document-menu", {
                          attrs: {
                            document: _vm.document,
                            "disable-sharing": _vm.disableSharing
                          },
                          on: {
                            "update:document": function($event) {
                              _vm.document = $event
                            },
                            "update:disableSharing": function($event) {
                              _vm.disableSharing = $event
                            },
                            "update:disable-sharing": function($event) {
                              _vm.disableSharing = $event
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "column" }, [
          _c(
            "div",
            { attrs: { id: "pdf-wrapper-signature" } },
            [
              _vm.pdfFile
                ? _c("pdfview", {
                    ref: "pdfView",
                    attrs: { "pdf-file": _vm.pdfFile, overlays: _vm.overlays },
                    on: {
                      handlePdfLoading: _vm.handlePdfLoading,
                      onSuccessfullSubmit: _vm.onSuccessfullSubmit,
                      onDeleteOverlay: _vm.onDeleteOverlay,
                      handlePdfMeta: _vm.handlePdfMeta
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c("signature-dialog", {
        attrs: { visibility: _vm.isSignaturePadShown },
        on: { handleDocumentCloseDialog: _vm.handleDocumentCloseDialog }
      }),
      _c("progress-save-dialog", {
        attrs: { visibility: _vm.isProgressSaveDialogShown },
        on: {
          handleProgressSaveCloseDialog: function($event) {
            _vm.isProgressSaveDialogShown = false
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("a", { attrs: { href: "/" } }, [_vm._v("Documents")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }