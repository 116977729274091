<template>
  <div>
    <nav
      v-if="isPdfLoadingComplete"
      class="navbar has-shadow is-fixed-bottom level"
    >
      <div class="level-left">
        <div class="level-item">
          <b-button
            v-if="signatureList.length == 0"
            size="is-medium"
            type="is-text action-button"
            icon-left="signature-freehand"
            @click="isSignaturePadShown = true"
          >
            Signature
          </b-button>

          <b-dropdown
            v-else
            aria-role="list"
            position="is-top-right"
            :mobile-modal="true"
          >
            <button
              slot="trigger"
              class="button is-text action-button is-medium"
            >
              <span class="icon">
                <i class="mdi mdi-signature-freehand" />
              </span>
              <span>Signature</span>
            </button>
            <b-dropdown-item
              v-for="sign in signatureList"
              :key="sign.id"
              aria-role="listitem"
              @click="addSelectedSignature(sign)"
            >
              <div class="preview-signature-menu">
                <img
                  width="100"
                  height="80"
                  :src="sign.image.src"
                >
              </div>
            </b-dropdown-item>
            <b-dropdown-item
              aria-role="listitem"
              @click="isSignaturePadShown = true"
            >
              <button class="button is-text action-button is-medium">
                Add Signature
              </button>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- <div class="level-item">
          <b-button
            size="is-medium"
            type="is-text action-button"
            icon-left="signature-text"
          >
            Text
          </b-button>
        </div> -->
      </div>
      <div class="level-right">
        <div class="level-item has-text-centered">
          <b-button
            icon-left="content-save-all-outline"
            class="button is-primary"
            @click="onFinish"
          >
            Submit
          </b-button>
        </div>
      </div>
    </nav>
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-half is-four-fifths">
          <div
            v-if="document"
            class="level "
          >
            <div class="level-left">
              <div class="level-item">
                <nav
                  class="breadcrumb"
                  aria-label="breadcrumbs"
                >
                  <ul>
                    <li>
                      <a href="/">Documents</a>
                    </li>
                    <li class="is-active">
                      <a aria-current="page">{{ document.name }}</a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <document-menu
                  :document.sync="document"
                  :disable-sharing.sync="disableSharing"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div id="pdf-wrapper-signature">
          <pdfview
            v-if="pdfFile"
            ref="pdfView"
            :pdf-file="pdfFile"
            :overlays="overlays"
            @handlePdfLoading="handlePdfLoading"
            @onSuccessfullSubmit="onSuccessfullSubmit"
            @onDeleteOverlay="onDeleteOverlay"
            @handlePdfMeta="handlePdfMeta"
          />
        </div>
      </div>
    </div>
    <signature-dialog
      :visibility="isSignaturePadShown"
      @handleDocumentCloseDialog="handleDocumentCloseDialog"
    />
    <progress-save-dialog
      :visibility="isProgressSaveDialogShown"
      @handleProgressSaveCloseDialog="isProgressSaveDialogShown = false"
    />
  </div>
</template>

<script>
import BaseUserInfo from "@/views/auth/BaseUserInfo";
import { eventHub } from "@/commons/event-bus";
import _ from "lodash";

import SignatureDialog from "./SignatureDialog";
import ProgressSaveDialog from "./ProgressSaveDialog";
import DocumentMenu from "@/views/documents/DocumentMenu";
import PDFView from "./PDFView";
import axios from "axios";

export default {
  components: {
    pdfview: PDFView,
    SignatureDialog,
    DocumentMenu,
    ProgressSaveDialog
  },
  extends: BaseUserInfo,
  props: {
    documentId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSignaturePadShown: false,
      isProgressSaveDialogShown: false,
      overlays: [],
      isPdfLoadingComplete: false,
      isLoading: true,
      pdfFile: null,
      document: null,
      scrollY: 0,
      disableSharing: false,
      signatureList: []
    };
  },
  watch: {
    documentId(value) {
      this.getDocument();
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    eventHub.$on("document.failure", this.pdfHandlingError);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    eventHub.$off("document.failure", this.pdfHandlingError);
  },
  mounted() {
    this.getDocument();
  },
  methods: {
    handlePdfMeta(_data) {
      if (_data == "password_protected") {
        this.disableSharing = true;
      }
    },
    handleScroll(event) {
      this.scrollY = window.pageYOffset;
    },
    async onFinish() {
      let self = this;
      if (this.overlays.length == 0) {
        this.$buefy.toast.open({
          message: "Document has not changed."
        });
        return;
      }
      let pdfBlob = await this.$refs.pdfView.onFinish().catch(function(e) {
        self.$buefy.toast.open({
          type: "is-danger",
          message: "PDF is encrypted. Cannot sign the PDF."
        });
      });
      this.finalizeFile(pdfBlob);
    },
    finalizeFile(blob) {
      if (!(blob instanceof Blob)) {
        return;
      }
      this.isProgressSaveDialogShown = true;
      this.isLoading = true;
      let url =
        "/organizations/" +
        this.$store.state.user.organization.id +
        "/documents/" +
        this.documentId;
      let formData = new FormData();
      formData.append("file", blob, this.documentId);
      axios
        .patch(url, formData, {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
          }
        })
        .then(response => {
          this.isLoading = false;
          this.onSuccessfullSubmit();
          this.isProgressSaveDialogShown = false;
        })
        .catch(e => {
          this.isLoading = false;
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Cannot upload file."
          });
          this.isProgressSaveDialogShown = false;
        });
    },
    randomId: function() {
      //https://gist.github.com/gordonbrander/2230317
      // Math.random should be unique because of its seeding algorithm.
      // Convert it to base 36 (numbers + letters), and grab the first 9 characters
      // after the decimal.
      return (
        "_" +
        Math.random()
          .toString(36)
          .substr(2, 9)
      );
    },
    handlePdfLoading() {
      this.isPdfLoadingComplete = true;
    },
    handleDocumentCloseDialog(flag, imag) {
      this.isSignaturePadShown = false;
      var img = document.createElement("img");
      img.setAttribute("type", "hidden");
      img.src = imag;
      img.onload = function() {
        this.overlays.push({
          id: this.randomId(),
          image: img,
          initialX:
            document.getElementById("overlay").getBoundingClientRect().width /
              2 -
            (img.width * 0.3) / 2,
          initialY: this.scrollY + window.innerHeight / 2 - img.height * 0.3,
          width: img.width,
          height: img.height
        });

        this.signatureList.push({
          id: this.randomId(),
          image: img,
          width: img.width,
          height: img.height
        });

        img.remove();
      }.bind(this);
    },
    onSuccessfullSubmit() {
      this.reloadPDF();
    },
    reloadPDF() {
      this.pdfFile = null;
      this.getDocument();
    },
    getDocument() {
      if (this.$store.state.user == null) {
        return;
      }
      this.isLoading = true;

      var url =
        "/organizations/" +
        this.$store.state.user.organization.id +
        "/documents/" +
        this.documentId;

      axios
        .get(url)
        .then(response => {
          this.isLoading = false;
          this.pdfFile =
            process.env.VUE_APP_BASE_URL +
            "/users/files/download?file=" +
            response.data.url;
          this.overlays = [];
          this.document = response.data;
          this.document.disable_sharing = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(e => {
          this.isLoading = false;
        });
    },
    onDeleteOverlay(item, overlayItems) {
      const index = overlayItems.findIndex(x => x.id === item.id);
      overlayItems.splice(index, 1);
      this.overlays = [];
      overlayItems.forEach(item => {
        // Change the original initial position to the current X,Y screen position
        // upon removal of item
        item.initialX = item.screenX;
        item.initialY = item.screenY;
        this.overlays.push(item);
      });
    },
    userInfoUpdate() {
      this.getDocument();
    },
    pdfHandlingError() {
      this.$buefy.toast.open({
        type: "is-danger",
        message: "Cannot download file from server to finalize."
      });
    },
    addSelectedSignature(item) {
      let newSign = _.clone(item);
      newSign.initialX =
        document.getElementById("overlay").getBoundingClientRect().width / 2 -
        (newSign.image.width * 0.3) / 2;
      newSign.initialY =
        this.scrollY + window.innerHeight / 2 - newSign.image.height * 0.3;
      newSign.id = this.randomId();
      this.overlays.push(newSign);
    }
  }
};
</script>

<style scoped>
#pdf-wrapper-signature {
  margin-bottom: 80px;
}

.level:not(:last-child) {
  margin-bottom: 0px;
}

.action-button {
  text-decoration: unset;
}
.preview-signature-menu {
  padding: 3px;
  border: 1px solid #cccccc;
}
</style>
