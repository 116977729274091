var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      staticClass: "document-dialog",
      style: { width: _vm.$responsive.width + "px" },
      attrs: { active: _vm.dialog, scroll: "keep", "can-cancel": false },
      on: {
        "update:active": function($event) {
          _vm.dialog = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: " dialog modal-card", staticStyle: { width: "auto" } },
        [
          _c("header", { staticClass: "modal-card-head" }, [
            _c("p", { staticClass: "modal-card-title" }, [
              _vm._v(" Saving... ")
            ])
          ]),
          _c("section", { staticClass: "modal-card-body" }, [
            _vm.isSavingProgress
              ? _c(
                  "div",
                  { staticClass: "save-progress-bar" },
                  [_c("b-progress", { attrs: { type: "is-success" } })],
                  1
                )
              : _vm._e(),
            _vm.isSavingFailed
              ? _c("div", [
                  _c("p", [
                    _vm._v(
                      " Cannot save the document. Please try again later. "
                    )
                  ])
                ])
              : _vm._e(),
            _vm.isSavingComplete
              ? _c("div", [_c("p", [_vm._v(" Saving is complete ")])])
              : _vm._e()
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }